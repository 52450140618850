/* eslint-disable global-require */
export default [
  {
    hasNativeSupport: () => typeof Array.from === 'function',
    polyfill (resolve) {
      require.ensure([], () => {
        resolve(require('core-js/modules/es.array.from'))
      }, null, 'polyfills/array-from')
    }
  },
  {
    hasNativeSupport: () => typeof Array.of === 'function',
    polyfill (resolve) {
      require.ensure([], () => {
        resolve(require('core-js/modules/es.array.of'))
      }, null, 'polyfills/array-of')
    }
  },
  {
    hasNativeSupport: () => typeof String.prototype.startsWith === 'function',
    polyfill (resolve) {
      require.ensure([], () => {
        resolve(require('core-js/modules/es.string.starts-with'))
      }, null, 'polyfills/string-start-with')
    }
  },
  {
    hasNativeSupport: () => typeof WeakSet === 'function',
    polyfill (resolve) {
      require.ensure([], () => {
        resolve(require('core-js/modules/es.weak-set'))
      }, null, 'polyfills/weakset')
    }
  },
  {
    hasNativeSupport: () => ('IntersectionObserver' in window && 'IntersectionObserverEntry' in window && 'intersectionRatio' in window.IntersectionObserverEntry.prototype),
    polyfill (resolve) {
      resolve(require('intersection-observer'), 'intersection-observer')
    }
  },
  {
    hasNativeSupport: () => typeof Array.prototype.includes === 'function',
    polyfill (resolve) {
      require.ensure([], () => {
        resolve(require('core-js/modules/es.array.includes'))
      }, null, 'polyfills/array-includes')
    }
  },
  {
    hasNativeSupport: () => Number.isNaN,
    polyfill (resolve) {
      Number.isNaN = function isNaN (input) {
                return typeof input === 'number' && input !== input; // eslint-disable-line
      }

      resolve()
    }
  },
  {
    hasNativeSupport: () => typeof Array.prototype.find === 'function',
    polyfill (resolve) {
      require.ensure([], () => {
        resolve(require('core-js/modules/es.array.find'))
      }, null, 'polyfills/array-find')
    }
  },
  {
    hasNativeSupport: () => typeof Array.prototype.flatMap === 'function',
    polyfill (resolve) {
      require.ensure([], () => {
        resolve(require('core-js/modules/es.array.flat-map'))
      }, null, 'polyfills/array-flat-map')
    }
  },
  {
    hasNativeSupport: () => typeof Array.prototype.findIndex === 'function',
    polyfill (resolve) {
      require.ensure([], () => {
        resolve(require('core-js/modules/es.array.find-index'))
      }, null, 'polyfills/array-find-index')
    }
  },
  {
    hasNativeSupport: () => typeof Array.prototype.fill === 'function',
    polyfill (resolve) {
      require.ensure([], () => {
        resolve(require('core-js/modules/es.array.fill'))
      }, null, 'polyfills/array-fill')
    }
  },
  {
    hasNativeSupport: () => typeof Object.assign === 'function',
    polyfill (resolve) {
      require.ensure([], () => {
        resolve(require('core-js/modules/es.object.assign'))
      }, null, 'polyfills/object-assign')
    }
  },
  {
    hasNativeSupport: () => typeof Object.values === 'function',
    polyfill (resolve) {
      require.ensure([], () => {
        resolve(require('core-js/modules/es.object.values'))
      }, null, 'polyfills/object-values')
    }
  },
  {
    hasNativeSupport: () => typeof Object.entries === 'function',
    polyfill (resolve) {
      require.ensure([], () => {
        resolve(require('core-js/modules/es.object.entries'))
      }, null, 'polyfills/object-entries')
    }
  }
]
