import { configureScope, init } from '@sentry/browser'

/**
 * Init sentry via frontend https://github.com/getsentry/sentry-javascript/tree/master/packages/browser
 * @return {undefined} void all the things here...we can't get anything useful out of this function
 */
export default function initSentry () {
  try {
    init({
      dsn: globals.config.sentryDSN
    })

    configureScope(scope => {
      scope.setTag('git_commit', globals.config.gitCommitHash)
      scope.setTag('logger', 'javascript')
    })
  } catch (error) {
    if (console && console.info) {
      console.error(error)
      console.info('Sentry was not initialized')
    }
  }
}
