import svg4everybody from 'svg4everybody'
import { version } from '@dreipol/vue-ui/src/util/detect/ie-detect'

export const injectSvg = (svgContainer) => {
  const svgUrl = svgContainer.getAttribute('data-src')

  // Handle the loaded, cache-busted SVG
  function onLoadSVG () {
    svgContainer.setAttribute('aria-hidden', 'true')
    svgContainer.className = 'u-icon-sprite'
    svgContainer.innerHTML = this.responseText

    svg4everybody({
      polyfill: version >= 9 && version <= 11
    })

    globals.state.svgSymbolsLoaded = true
    window.dispatchEvent(new CustomEvent('icons-loaded'))
  }

  function onErrorSVG (jqXHR, textStatus, errorThrown) {
    console.log(jqXHR)
    console.log(textStatus)
    console.log(errorThrown)
  }

  // Load the SVG separately without cache
  const SVGRequest = new XMLHttpRequest()

  SVGRequest.addEventListener('load', onLoadSVG)
  SVGRequest.addEventListener('error', onErrorSVG)
  SVGRequest.addEventListener('abort', onErrorSVG)

  SVGRequest.open('GET', svgUrl, true)
  SVGRequest.send()
}

/**
 * Polyfills SVG usage for older browsers and helps using the browser cache for symbols.
 */
document.addEventListener('DOMContentLoaded', () => {
  const svgContainer = document.querySelector('.u-icon-source')

  if (svgContainer) {
    injectSvg(svgContainer)
  }
})
