import 'promise-polyfill/src/polyfill'
import 'custom-event-polyfill'
import polyfills from './polyfill-registry'

export const installPolyfills = (function () {
  const polyfillPromises = []

  polyfills.forEach(({ hasNativeSupport, polyfill }) => {
    if (!hasNativeSupport()) {
      polyfillPromises.push(new Promise(polyfill))
    }
  })

  return Promise.all(polyfillPromises)
}())

export const handleErrors = (err) => {
  setTimeout(() => {
    if (err instanceof Error) {
      throw err
    } else {
      console.warn(err)
    }
  })
}
